import listInsiderInfo from '../../../assets/files/ru/listInsiderInfo.pdf';

export default {
    seo: {
      title: 'Перечень инсайдерской информации | Банк 131',
      description: 'Перечень инсайдерской информации'
    },
    title: 'Перечень инсайдерской информации',
    content: [
         {
             label: 'Дата размещения 22.11.2022',
             title: 'Перечень инсайдерской информации ООО «Банк 131».PDF',
             link: listInsiderInfo
         }
     ]
}